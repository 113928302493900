import service from './network';

export function queryRoleListApi() {
    return service.request({
        url: '/security/module/role/role/list',
        method: 'get',
    })
}

export function queryUserRoleListApi(data) {
    return service.request({
        url: '/security/module/user/user/role',
        method: 'get',
        params: data
    })
}

export function grantSubUserRoleApi(data) {
    return service.request({
        url: '/security/module/user/grant/role',
        method: 'put',
        data
    })
}

export function queryRoleMgtListApi(data) {
    return service.request({
        url: '/security/module/role/query',
        method: 'post',
        data
    })
}


export function updateRoleMgtInfoApi(data) {
    return service.request({
        url: '/security/module/role/update',
        method: 'put',
        data
    })
}

export function queryRoleModulesApi(data) {
    return service.request({
        url: '/security/module/role/query/has/routes',
        method: 'get',
        params: data
    })
}

export function grantRouteToRoleApi(data) {
    return service.request({
        url: '/security/module/role/grant/route',
        method: 'put',
        data
    })
}

export function deleteRoleMgtApi(data) {
    return service.request({
        url: '/security/module/role/delete',
        method: 'delete',
        data
    })
}

export function queryUserRouteTreeApi(data) {
    return service.request({
        url: '/user/route/tree/all',
        method: 'get',
        params: data
    })
}