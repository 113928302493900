<template>
    <a-modal v-model="visible" title="模块列表" width="900px" okText="关闭" :footer="null" @cancel="handleCancel">
        <a-table  :scroll="{  y: 640 }" :columns="columns" :data-source="data" style="margin-top: 20px;"   :loading="loading" :pagination="pagination" :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange}" rowKey="roleCode" >
        <span slot="allowDerived" slot-scope="text,record">{{record.allowDerived ? '是' : '否'}}</span>
        </a-table>
        <a-row>
            <a-popconfirm
                title="确认授予？"
                ok-text="是"
                cancel-text="否"
                @confirm="grantModule(record)"
                style="margin-right: 10px;float:right;"
            >
                <a-button type="primary">点击授予</a-button>
            </a-popconfirm>
        </a-row>
    </a-modal>
</template>

<script>
const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
        console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
    onSelect: (record, selected, selectedRows) => {
        console.log(record, selected, selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
        console.log(selected, selectedRows, changeRows);
    },
    getCheckboxProps: record => ({
        props: {
        disabled: !record.allowDerived, // Column configuration not to be checked
        },
    }),
};
import {  queryRoleListApi, queryUserRoleListApi, grantSubUserRoleApi} from '@/api/role'
export default {
    data() {
        return{
            selectedRows: [],
            currentUserModules: [],
            selectedRowKeys: [],
            rowSelection,
            visible: false,
            record: {},
            params: { page: 1, limit: 10},
            loading: false,
            pagination: {
                pageSize: 30,
                current: 1,
                showQuickJumper: true,
                onChange: (current) => this.changePage(current),
            },
            columns: [
                {
                    title: '角色编码',
                    dataIndex: 'roleCode',
                    key: 'roleCode'
                },
                {
                    title: '角色名称',
                    dataIndex: 'roleName',
                    key: 'roleName'
                },
                {
                    title: '角色描述',
                    dataIndex: 'represent',
                    key: 'represent'
                },
            ],
            data: []
        }
    },
    methods: {
        handleCancel() {
            this.selectedRowKeys = []
            this.selectedRows = []
        },
        onSelectChange(selectedRowKeys, selectedRows) {
            this.selectedRowKeys = selectedRowKeys;
            this.selectedRows = selectedRows
        },
        showRole(record) {
            this.visible = true
            this.record = record
            this.queryUserModuleList()
            this.querySubUserModuleList()
        },
        querySubUserModuleList() {
            queryUserRoleListApi({userCode: this.record.userCode}).then(res => {
                this.currentUserModules = res.result
                this.selectedRowKeys = this.currentUserModules
            })
        },
        queryUserModuleList() {
            this.loading = true
            this.params.page = this.pagination.current
            this.params.limit = this.pagination.pageSize
            queryRoleListApi({...this.params}).then(res => {
                this.data = res.result
                this.loading = false
            })
        },
        grantModule(){
            grantSubUserRoleApi({userCode: this.record.userCode, roleCodes: this.selectedRowKeys}).then(res => {
                this.$message.success('操作成功')
                this.$emit('query')
                this.selectedRowKeys = []
                this.selectedRows = []
                this.visible = false
            }).catch(() => {
                this.$message.error('操作失败')
            })
        },
        changePage(current) {
            this.pagination.current = current
            this.queryUserModuleList()
        },
    }
}
</script>