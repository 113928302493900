<template>
    <a-modal v-model="visible" :title="record.userCode ? '修改' : '新增用户'" @ok="handleOk">
        <p v-if="record.userCode">用户编码：<a-input placeholder="用户编码" v-model="record.userCode" disabled/></p>
        <p>用户名：<a-input placeholder="用户名" v-model="record.username"/></p>
        <p>真实姓名：<a-input placeholder="真实姓名" v-model="record.realName"/></p>
        <p>性别：
            <a-select allowClear :defaultValue="record.gender" @change="handleChange" style="width: 120px;">
                <a-select-option value="1">男</a-select-option>
                <a-select-option value="2">女</a-select-option>
                <a-select-option value="3">未知</a-select-option>
            </a-select>
        </p>
        <p>电话：<a-input placeholder="电话" v-model="record.phone"/></p>
        <p>邮箱：<a-input placeholder="邮箱" v-model="record.mail"/></p>
    </a-modal>
</template>

<script>
import { updateModuleUserInfoApi } from '@/api/user'
export default {
    data() {
        return{
            visible: false,
            record: {}
        }
    },
    methods: {
        showEdit(record) {
            this.visible = true
            this.record = record
        },
        handleOk() {
            updateModuleUserInfoApi({...this.record}).then(res => {
                if(res.code === '0') {
                    this.$message.success('操作成功')
                    this.$emit('query')
                    this.visible = false
                }
                }).catch(() => {
                    this.$message.success('操作失败')
                })
        },
        handleChange(e) {
            this.record.gender = e
        }
    }
}
</script>