<template>
  <div class="content">
      <a-form layout="inline">
            <a-form-item label="用户名">
              <a-input placeholder="请输入关键词" v-model="params.username" allowClear/>
            </a-form-item>
            <a-form-item label="真实姓名">
              <a-input placeholder="请输入关键词" v-model="params.realName" allowClear/>
            </a-form-item>
            <a-form-item label="电话">
              <a-input placeholder="请输入关键词" v-model="params.phone" allowClear/>
            </a-form-item>
            <a-form-item >
              <a-button type="primary" @click="onSearch">查询</a-button>
              <a-button type="default" @click="addUser" style="margin-left: 10px;">新增</a-button>
            </a-form-item>
      </a-form>
      <a-table  :scroll="{  y: 640, x: 1700 }" :columns="columns" :data-source="tableData" style="margin-top: 20px;"   :loading="loading" :pagination="pagination" >
        <span slot="userStatusStr" slot-scope="text, record">
          <a-badge status="success" :text="record.userStatusStr" v-if="record.userStatusStr == '可用'"/>
          <a-badge status="error" :text="record.userStatusStr" v-else/>
        </span>
        <span slot="gender" slot-scope="text, record">
          {{record.gender == 1 ? '男' : (record.gender == 2 ? '女' :'未知')}}
        </span>
        <span slot="action" slot-scope="text, record">
            <a @click="showEdit(record)" style="margin-right: 10px;">修改</a>
            <a-popconfirm
                    :title="record.userStatusStr == '可用' ? '确认禁用' : '确认启用' + '?'"
                    ok-text="是"
                    cancel-text="否"
                    @confirm="updateStatus(record)"
                    style="margin-right: 10px;"
            >
              <a>{{record.userStatusStr == '可用' ? '禁用' : '启用'}}</a>
            </a-popconfirm>
            <a-popconfirm
                title="确认重置密码？"
                ok-text="是"
                cancel-text="否"
                @confirm="resetPassword(record)"
                style="margin-right: 10px;"
            >
              <a>重置密码</a>
            </a-popconfirm>
            <a-popconfirm
                title="确认删除？"
                ok-text="是"
                cancel-text="否"
                @confirm="deleteUser(record)"
                style="margin-right: 10px;"
            >
              <a>删除</a>
            </a-popconfirm>
            <a @click="showModule(record)">授予模块</a>
            <a @click="showRole(record)">授予角色</a>
        </span>
      </a-table>
      <edit ref="edit" @query="queryModuleUser"/>
      <module ref="module" @query="queryModuleUser" />
      <role ref="role" @query="queryModuleUser" />
  </div>
</template>
<script>
import module from './component/module.vue'
import edit from './component/edit.vue'
import role from './component/role.vue'
import { queryModuleUserApi , updateModuleUserStatusApi, resetUserPasswordApi, deleteUserApi} from '@/api/user'
export default {
  components: { edit, module , role},
  data() {
    return {
      tableData: [],
      columns: [
        {
          title: 'id',
          dataIndex: 'id',
          key: 'id'
        },
        {
          title: '用户名',
          dataIndex: 'username',
          key: 'username'
        },
        {
          title: '用户编码',
          dataIndex: 'userCode',
          key: 'userCode'
        },
        {
          title: '真实姓名',
          dataIndex: 'realName',
          key: 'realName'
        },
        {
          title: '性别',
          dataIndex: 'gender',
          scopedSlots: { customRender: 'gender' },
        },
        {
          title: '电话',
          dataIndex: 'phone',
          key: 'phone'
        },
        {
          title: '邮箱',
          dataIndex: 'mail',
          key: 'mail'
        },
        {
          title: '当前状态',
          dataIndex: 'userStatusStr',
          key: 'userStatusStr',
          scopedSlots: { customRender: 'userStatusStr' },
        },
        {
          title: '操作',
          dataIndex: 'action',
          fixed: 'right',
          width: '280',
          scopedSlots: { customRender: 'action' },
        }
      ],
      loading: false,
      params: {
        username: '',
        realName: '',
        phone: ''
      },
      pagination: {
        pageSize: 10,
        current: 1,
        total: 0,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total) => {
          return '共' + total + '条'
        },
        pageSizeOptions: ['10', '20', '30', '40', '50', '60', '70'],
        onChange: (current, size) => this.changePage(current, size),
        onShowSizeChange:(current, pageSize)=>this.changePage(current, pageSize),
      },
    }
  },
  mounted() {
    this.queryModuleUser()
  },
  methods: {
    deleteUser(record){
      deleteUserApi([record.id]).then(res => {
         if(res.code == '0') {
           this.$message.success('删除成功')
           this.queryModuleUser()
         }
      })
    },
    addUser() {
      this.$refs.edit.showEdit({userCode: '', username: '', realName: '', phone: '',mail: '',gender: ''})
    },
    showRole(record){
      this.$refs.role.showRole(record)
    },
    resetPassword(record){
      resetUserPasswordApi(record.userCode).then(res => {
        this.$message.success('操作成功')
      }).catch(() => {
        this.$$message.error('操作失败')
      })
    },
    showModule(record) {
      this.$refs.module.showModule(record)
    },
    showEdit(record){
      this.$refs.edit.showEdit(record)
    },
    updateStatus(record){
      updateModuleUserStatusApi(record.userCode).then(res => {
        this.$message.success('操作成功')
        this.queryModuleUser()
      }).catch(() => {
        this.$message.error('操作失败')
      })
    },
    onSearch() {
      this.pagination.current = 1
      this.queryModuleUser()
    },
    changePage(current, pageSize) {
      this.pagination.current = current
      this.pagination.pageSize = pageSize
      this.queryModuleUser()
    },
    queryModuleUser() {
      this.loading = true
      this.params.page = this.pagination.current
      this.params.limit = this.pagination.pageSize
      queryModuleUserApi({...this.params}).then(res => {
        this.tableData = res.result.content
        this.loading = false
        this.pagination.current = res.result.pageNo + 1
        this.pagination.total = res.result.totalElements
        this.params.page = this.pagination.current
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  padding: 15px;
  .ant-card-head{
    background: #fbfbfb;
  }
}
</style>
